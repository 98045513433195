<template>
    <v-layout wrap>
        <v-flex sm4
                class="mb-5"
                :class="{'pr-3': $vuetify.breakpoint.smAndUp}">
            <RadioGroup :label="$t('labels.action')"
                        :options="actionOptions"
                        v-model="action"
                        rules="required"></RadioGroup>
        </v-flex>
        <v-flex xs12>
            <v-layout :class="[$vuetify.breakpoint.xs ? 'gap-row' : 'gap-col']"
                      align-stretch
                      :wrap="$vuetify.breakpoint.xs">
                <v-flex sm4>
                    <v-card class="full-height">
                        <v-card-title>
                            <h2 class="font-weight-medium text--l mb-3">{{ $t('navigation.persons') }}</h2>
                        </v-card-title>
                        <v-card-text>
                            <Autocomplete :label="$t('navigation.persons_groups')"
                                          v-model="employeesGroupsIds"
                                          :items="filterableEmployeesGroups"
                                          name="employeesGroups"
                                          :multiple="true"
                                          clearable
                                          :loading="isEmployeesGroupsLoading"
                                          :search-input.sync="searchEmployeesGroups"
                                          @click:clear="getEmployeesGroups"
                                          @load-more-items="getEmployeesGroupsLazyLoading"></Autocomplete>
                            <Autocomplete :label="$t('navigation.persons')"
                                          v-model="employeesIds"
                                          :items="employees"
                                          name="employeesIds"
                                          :multiple="true"
                                          clearable
                                          :loading="isEmployeesLoading"
                                          :search-input.sync="searchEmployees"
                                          @click:clear="getEmployees"
                                          @load-more-items="getEmployeesLazyLoading"></Autocomplete>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex sm4>
                    <v-card class="full-height">
                        <v-card-title>
                            <h2 class="font-weight-medium text--l mb-3">{{ $t('labels.customer_site_location') }}</h2>
                        </v-card-title>
                        <v-card-text>
                            <Autocomplete :label="$t('labels.customers')"
                                          name="customersIds"
                                          :multiple="true"
                                          clearable
                                          @click:clear="getCustomers(null, null, true)"
                                          @load-more-items="getCustomersLazyLoading(true)"
                                          :items="listingCustomers"
                                          :loading="isCustomersLoading"
                                          :search-input.sync="searchCustomers"
                                          v-model="customersIds"></Autocomplete>
                            <Autocomplete :label="$t('labels.sites')"
                                          name="sitesIds"
                                          :items="sites"
                                          :multiple="true"
                                          clearable
                                          @click:clear="getSites(null, null, customersIds)"
                                          @load-more-items="getSitesLazyLoading(customersIds)"
                                          :loading="isSitesLoading"
                                          :search-input.sync="searchSitesPerCustomer"
                                          :disabled="!customersIds.length > 0"
                                          :success-messages="showSiteSuccessMessage"
                                          v-model="sitesIds"></Autocomplete>
                            <Autocomplete :label="$t('labels.locations')"
                                          name="locationsIds"
                                          clearable
                                          :multiple="true"
                                          @click:clear="getLocations(null, null, siteId)"
                                          @load-more-items="getLocationsLazyLoading(siteId)"
                                          :items="locations"
                                          :loading="isLocationsLoading"
                                          :search-input.sync="searchLocationsPerSite"
                                          :disabled="!sitesIds.length > 0"
                                          :success-messages="showLocationSuccessMessage"
                                          v-model="locationsIds"></Autocomplete>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex sm4>
                    <v-card class="full-height">
                        <v-card-title>
                            <h2 class="font-weight-medium text--l mb-3">{{ $t('navigation.templates') }}</h2>
                        </v-card-title>
                        <v-card-text>
                            <Autocomplete :label="$t('navigation.templates')"
                                          name="templatesIds"
                                          :multiple="true"
                                          clearable
                                          @click:clear="getTemplates(null)"
                                          @load-more-items="getTemplatesLazyLoading(null)"
                                          :items="filterableTemplates"
                                          :loading="isTemplatesLoading"
                                          :search-input.sync="searchTemplates"
                                          v-model="templatesIds"></Autocomplete>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex xs12 class="mt-5">
            <v-layout align-center
                      justify-end>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import RadioGroup from '@/components/widgets/forms/RadioGroup';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';

export default {
    name: 'GroupTemplatesAssignmentForm',
    components: { RadioGroup, Autocomplete },
    mixins: [
        debouncedEmployeesGroups,
        debouncedEmployees,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations,
        debouncedTemplates
    ],
    data: () => ({
        loader: false
    }),
    computed: {
        actionOptions () {
            return [
                {
                    text: this.$t('labels.assign_templates'),
                    value: 1
                },
                {
                    text: this.$t('labels.unassign_templates'),
                    value: 2
                }
            ];
        },
        ...mapGetters('employeesGroups', [
            'filterableEmployeesGroups'
        ]),
        ...mapGetters('employees', [
            'employees'
        ]),
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        ...mapGetters('locations', [
            'locations'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        ...mapState('templates', [
            'totalTemplatesAmount'
        ]),
        ...mapFields('templates', [
            'groupAssignmentData.action',
            'groupAssignmentData.employeesGroupsIds',
            'groupAssignmentData.employeesIds',
            'groupAssignmentData.customersIds',
            'groupAssignmentData.sitesIds',
            'groupAssignmentData.locationsIds',
            'groupAssignmentData.templatesIds'
        ]),
        showSiteSuccessMessage () {
            return this.customersIds.length > 0 ? '' : this.$t('messages.choose_customer');
        },
        showLocationSuccessMessage () {
            return this.sitesIds.length > 0 ? '' : this.$t('messages.choose_site');
        }
    },
    watch: {
        customersIds: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.getSites(null, null, newVal);
                }
                if (newVal.length === 0) {
                    this.sitesIds = [];
                    this.locationsIds = [];
                }
            },
            deep: true
        },
        sitesIds: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.getLocations(null, null, newVal);
                }
                if (newVal.length === 0) {
                    this.locationsIds = [];
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.gap-col {
    gap: 0 16px;
}
.gap-row {
    gap: 16px 0;
}
</style>
