<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.group_template_assignment') }}</h1>
        <GroupTemplatesAssignmentForm></GroupTemplatesAssignmentForm>
    </div>
</template>

<script>
import GroupTemplatesAssignmentForm from '@/components/templatesConfigurations/groupAssignment/GroupTemplatesAssignmentForm';

export default {
    name: 'GroupTemplatesAssignment',
    components: { GroupTemplatesAssignmentForm },
    data: () => ({
        params: {
            'page[number]': '1',
            'filter[archive]': false,
            'filter[current]': true
        }
    }),
    beforeRouteEnter (to, from, next) {
        next(vm => {
            // TODO: check right abilities
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.TEMPLATE)) {
                vm.$store.dispatch('employeesGroups/getEmployeesGroups');
                vm.$store.dispatch('employees/getEmployees');
                vm.$store.dispatch('customers/getCustomers');
                vm.$store.dispatch('templates/getTemplates', vm.params);
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('templates/RESET_GROUP_ASSIGNMENT_DATA');
        next();
    }
};
</script>

<style scoped>

</style>
